export enum CONTENT_TYPE {
    DEFAULT = "content-page-article",
    PROFESSIONAL = "content-page-article-professional",
    LIST = "content-page-list",
    CONTENT_CARD = "content-block-card-contentcard",
    UPDATES_CARD = "content-block-card-updatescard",
    ARTICLE_UPDATES_CARD = "content-block-card-articleupdatescard"
}

export function getContentType(types?: string[]): CONTENT_TYPE {
    if (!types) {
        return CONTENT_TYPE.DEFAULT
    }

    const type = types.join("-").toLowerCase()
    if (type.startsWith(CONTENT_TYPE.LIST)) {
        return CONTENT_TYPE.LIST
    }

    if (type.startsWith(CONTENT_TYPE.PROFESSIONAL)) {
        return CONTENT_TYPE.PROFESSIONAL
    }

    if (type.startsWith(CONTENT_TYPE.CONTENT_CARD)) {
        return CONTENT_TYPE.CONTENT_CARD
    }

    if (type.startsWith(CONTENT_TYPE.UPDATES_CARD)) {
        return CONTENT_TYPE.UPDATES_CARD
    }

    if (type.startsWith(CONTENT_TYPE.ARTICLE_UPDATES_CARD)) {
        return CONTENT_TYPE.ARTICLE_UPDATES_CARD
    }

    return CONTENT_TYPE.DEFAULT
}
