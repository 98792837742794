import { useRouter } from "next/router"
import * as React from "react"

import { grey } from "@mui/material/colors"
import { styled } from "@mui/material/styles"

export interface MainProps {
    /**
     * The content of the right aside element.
     * If not defined, the main element
     * will take up its space by default.
     * If you want keep the main element's default width,
     * set this to `null`.
     */
    rightAside?: React.ReactElement | null
    /**
     * The content of the left aside element.
     * If not defined, the main element
     * will take up its space by default.
     * If you want keep the main element's width unaffected,
     * set this to `null`.
     */
    leftAside?: React.ReactElement | null
    placeholderLeft?: boolean
    placeholderRight?: boolean
    children: React.ReactNode
}

export default function Main(props: MainProps) {
    const {
        children,
        leftAside,
        rightAside,
        placeholderRight = !!rightAside,
        placeholderLeft = !!leftAside
    } = props

    const router = useRouter()
    const nobackground = router.pathname === "/"

    let contentWidth = 1280
    if (leftAside) {
        contentWidth -= 320
    }

    if (rightAside) {
        contentWidth -= 320
    }

    return (
        <Layout>
            {placeholderLeft ? (
                <LeftAside className="no-print">{leftAside}</LeftAside>
            ) : null}
            <StyledMain
                nobackground={nobackground}
                width={contentWidth}>
                {children}
            </StyledMain>
            {placeholderRight ? (
                <RightAside className="no-print">{rightAside}</RightAside>
            ) : null}
        </Layout>
    )
}

const Layout = styled("div")(() => ({
    position: "relative",
    display: "flex",
    flex: 1,
    justifyContent: "center",
    paddingTop: 0
}))

const StyledMain = styled("main")<{
    nobackground: boolean
    width: number
}>(({ nobackground, width, theme }) => ({
    width: `min(100%, ${width}px)`,
    padding: theme.spacing(0, 2, 2, 2),
    background: nobackground ? undefined : theme.palette.common.white
}))

const LeftAside = styled("aside")(({ theme }) => {
    const { header, breadcrumbs } = theme.constants.height

    return {
        [theme.breakpoints.up("md")]: {
            position: "sticky",
            top: header + breadcrumbs,
            height: `calc(100vh - ${header + breadcrumbs}px)`,
            float: "left",
            minWidth: `${theme.constants.width.leftAside}px`,
            width: `${theme.constants.width.leftAside}px`,
            backgroundColor: grey[200]
        }
    }
})

const RightAside = styled("aside")(({ theme }) => {
    const { header, breadcrumbs } = theme.constants.height

    return {
        display: "none",
        width: theme.constants.width.rightAside,
        [theme.breakpoints.up("lg")]: {
            display: "block",
            position: "sticky",
            top: header + breadcrumbs,
            height: `calc(100vh - ${header + breadcrumbs}px)`,
            float: "left",
            width: theme.constants.width.rightAside
        }
    }
})
